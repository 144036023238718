/**
 *
 * @description: hooks组件
 * @directory src/hooks
 * @author ldl
 * @date 2022/9/20
 */
import { ref } from "vue";

export default function () {
  const homeShow = ref(1);
  const scroll = ref(1);
  const setScroll = (num: number) => {
    window.scrollTo({
      top: num,
      behavior: "smooth",
    });
  };
  const topNum = ref(256);

  const menu = () => {
    scroll.value =
      document.documentElement.scrollTop || document.body.scrollTop;
    topNum.value = 4800 - scroll.value + 265 + 40;
    if (topNum.value > 265) {
      topNum.value = 265;
    }
    if (scroll.value < 2399) {
      homeShow.value = 1;
    } else if (scroll.value > 2399 && scroll.value < 3100) {
      homeShow.value = 2;
    } else if (scroll.value > 3099 && scroll.value < 3800) {
      homeShow.value = 3;
    } else if (scroll.value > 3799 && scroll.value < 4500) {
      homeShow.value = 4;
    } else if (scroll.value > 4499) {
      homeShow.value = 5;
    }
    console.log(scroll.value);
  };
  window.addEventListener("scroll", menu);

  return {
    topNum,
    setScroll,
    homeShow,
    scroll,
  };
}
