<template>
  <router-view />
</template>

<style lang="scss">
body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  //color: #2c3e50;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth !important;
  a {
    text-decoration: none; /* 去除默认的下划线 */

    color: #dcdcdc; /* 去除默认的颜色和点击后变化的颜色 */
  }
}
</style>
